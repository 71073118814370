import { Button, IconButton, Typography, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const PhotoModalHeader = ({ onCloseModal, addressProp, onClickBack, backLabel = 'Back' }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      p={1}
      sx={{
        height: 70,
        backgroundColor: theme => theme.colors.alpha.white[100],
      }}
    >
      <Box flexBasis="0" flexGrow={1}>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={onClickBack}
          sx={{
            color: theme => theme.colors.alpha.black[80],
            justifyContent: 'flex-start',
          }}
        >
          {backLabel}
        </Button>
      </Box>

      <Box flex={1} sx={{ textAlign: 'center' }}>
        <Typography
          variant="h5"
          lineHeight="1.1"
          fontWeight="600"
          noWrap
          sx={{
            '::selection': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {addressProp}
        </Typography>
      </Box>

      <Box flexBasis="0" flexGrow={1} display="flex" justifyContent="flex-end">
        {onCloseModal && (
          <IconButton
            size="small"
            onClick={onCloseModal}
            sx={{
              color: theme => theme.colors.alpha.black[80],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default PhotoModalHeader
