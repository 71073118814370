import React, { useState, useEffect } from 'react'
import { Box, ImageList, ImageListItem, Skeleton } from '@mui/material'
import Lightbox from './Lightbox'
import PhotoFilters from './PhotoFilters'
import { usePhotoList } from '../../../../CustomHooks/usePhotoList'
import { usePhotoFilters } from '../../../../CustomHooks/usePhotoFilters'

const MobilePhotoList = ({ photos, onClose, photoIndex, setPhotoIndex }) => {
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight)

  const { itemRefs, scrollableContainerRef, loadedImages, handleImageLoad, scrollToTop } =
    usePhotoList(photos, photoIndex, setPhotoIndex)
  const { selectedPhotoFilter, photoFilters, setFilter, filteredPhotos } = usePhotoFilters(
    photos,
    scrollToTop
  )

  // Listen for orientation changes (Landscape vs Portrait)
  useEffect(() => {
    const handleResize = () => {
      const isNowLandscape = window.innerWidth > window.innerHeight
      setIsLandscape(isNowLandscape)
      // We remove all filters when in landscape mode
      if (isNowLandscape && selectedPhotoFilter) setFilter(null)
    }
    window.addEventListener('resize', handleResize)
    // Cleanup function removes listener
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const imgStyles = isLandscape
    ? {
        maxHeight: '100vh',
        width: '500px',
        objectFit: 'cover',
      }
    : { width: '100%', height: '260px', objectFit: 'cover' }

  return (
    <Lightbox isOpen={true} onClose={onClose}>
      <Box position="relative" id="mobile-photo-list">
        <ImageList
          ref={scrollableContainerRef}
          variant="standard"
          cols={isLandscape ? photos.edges.length : 1}
          gap={8}
          sx={theme => ({
            width: '100%',
            height: '100vh',
            paddingBottom: isLandscape ? 0 : theme => theme.spacing(8),
            display: 'flex',
            flexDirection: isLandscape ? 'row' : 'column',
            justifyContent: 'flex-start',
          })}
        >
          {filteredPhotos.map((photo, index) => (
            <ImageListItem key={photo.node.photo_url} ref={el => (itemRefs.current[index] = el)}>
              {!loadedImages[photo.node.photo_url] && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    ...imgStyles,
                    position: 'absolute',
                  }}
                />
              )}
              <img
                loading="lazy"
                src={photo.node.photo_url}
                alt={photo.node.room_name}
                style={imgStyles}
                onLoad={() => handleImageLoad(photo.node.photo_url)}
              />
            </ImageListItem>
          ))}
        </ImageList>
        {photoFilters.length > 0 && !isLandscape && (
          <PhotoFilters
            photoCount={photos.edges.length}
            photoFilters={photoFilters}
            selectedPhotoFilter={selectedPhotoFilter}
            setFilter={setFilter}
            isMobileDevice={true}
          />
        )}
      </Box>
    </Lightbox>
  )
}

export default MobilePhotoList
