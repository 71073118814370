import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Keyboard } from 'swiper/modules'
import 'swiper/swiper-bundle.css'
import { Box } from '@mui/material'
import PhotoModalHeader from '../../../Homebuyer/Pages/Listing/Header/PhotoModalHeader'
import useKeyPress from '../../../CustomHooks/useKeyPress'

const DesktopPhotoCarousel = ({ photos, photoIndex, address, onCloseModal, goBack }) => {
  useKeyPress('Escape', onCloseModal)

  return (
    <Box position="relative" id="desktop-photo-carousel" sx={{ height: '97vh' }}>
      <PhotoModalHeader
        onCloseModal={onCloseModal}
        addressProp={address}
        onClickBack={goBack}
        backLabel={'Photo List'}
      />
      <Swiper
        initialSlide={photoIndex}
        slidesPerView={1}
        id="swiper"
        style={{ height: '80vh', maxWidth: 1600 }}
        navigation
        keyboard={{ enabled: true }}
        modules={[Navigation, Keyboard]}
      >
        {photos.edges.map(photo => {
          const node = photo.node
          return (
            <SwiperSlide
              key={node.photo_url}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                loading="lazy"
                component="img"
                src={node.photo_url}
                sx={theme => ({
                  width: '80%',
                  objectFit: 'contain',
                  height: '65vh',
                  backgroundColor: theme.colors.alpha.white[10],
                  '::selection': {
                    backgroundColor: 'transparent',
                  },
                })}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

export default DesktopPhotoCarousel
