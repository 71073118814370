import { useState, useRef, useEffect } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

export const usePhotoList = (photos, photoIndex, setPhotoIndex, isLandscape) => {
  const itemRefs = useRef([])
  const scrollableContainerRef = useRef()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [shouldScrollToPhotoIndex, setScrollToPhotoIndex] = useState(photoIndex > 0)
  const [loadedImages, setLoadedImages] = useState({})

  useEffect(() => {
    // Scroll to the specified photoIndex when the component mounts
    if (!shouldScrollToPhotoIndex) return
    const imageUrl = photos.edges[photoIndex]?.node.photo_url
    const item = itemRefs.current[photoIndex]
    const isReadyToScroll = (item && loadedImages[imageUrl]) || isLandscape
    if (isReadyToScroll) {
      if (isMobile) {
        itemRefs.current[photoIndex].scrollIntoView({
          behavior: 'auto',
          block: isLandscape ? 'start' : 'center',
          inline: isLandscape ? 'center' : 'start',
        })
      } else {
        // Desktop
        const scrollableContainer = scrollableContainerRef.current
        const topPaddingForScrollingAnimation = 200
        const offsetTop = item.offsetTop - topPaddingForScrollingAnimation
        scrollableContainer.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
      setScrollToPhotoIndex(false)
    }
  }, [photoIndex, loadedImages, shouldScrollToPhotoIndex])

  const handleImageLoad = photoUrl => {
    setLoadedImages(prevState => ({
      ...prevState,
      [photoUrl]: true,
    }))
  }

  return {
    itemRefs,
    scrollableContainerRef,
    loadedImages,
    handleImageLoad,
  }
}
