import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box } from '@mui/material'
import { gql } from '@apollo/client'
import PhotoGrid from './Photos/PhotoGrid'
import MobilePhotoCarousel from './Photos/MobilePhotoCarousel'
import MobilePhotoList from './Photos/PhotoList/MobilePhotoList'

export const LISTING_PHOTOS_FRAGMENT = gql`
  fragment listingPhotosFragment on Property {
    id
    photos {
      edges {
        node {
          id
          photo_url
          room_name
        }
      }
    }
  }
`

const ListingPhotos = ({ photos, openDesktopPhotoList, photoIndex, setPhotoIndex }) => {
  const [showPhotoList, setShowPhotoList] = useState(false)
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const onClose = () => {
    setShowPhotoList(false)
    setPhotoIndex(0)
  }

  const openMobilePhotoList = index => {
    setPhotoIndex(index)
    setShowPhotoList(true)
  }

  return (
    <>
      {isSmallerThanMd ? (
        <>
          {showPhotoList && (
            <MobilePhotoList
              photos={photos}
              photoIndex={photoIndex}
              setPhotoIndex={setPhotoIndex}
              onClose={onClose}
            />
          )}
          <MobilePhotoCarousel photos={photos} handlePhotoClick={openMobilePhotoList} />
        </>
      ) : (
        <Box className="photo-grid">
          <PhotoGrid photos={photos} onPhotoClick={openDesktopPhotoList} />
        </Box>
      )}
    </>
  )
}

export default ListingPhotos
