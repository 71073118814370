import { useEffect } from 'react'

const useKeyPress = (targetKey, handler) => {
  useEffect(() => {
    const keyHandler = ({ key }) => {
      if (key === targetKey) {
        handler()
      }
    }

    // Add event listener for keydown
    window.addEventListener('keydown', keyHandler)

    // Cleanup function to remove the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  }, [targetKey, handler])
}

export default useKeyPress
