import { useState, useMemo } from 'react'

export const usePhotoFilters = (photos, onSetPhotoFilter) => {
  const [selectedPhotoFilter, setPhotoFilter] = useState()

  const photoFilters = useMemo(() => {
    let filterSet = [...new Set(photos.edges.map(image => image.node.room_name))]
    filterSet = filterSet.filter(roomName => roomName !== null)

    return filterSet.map(filter => ({
      value: filter,
      count: photos.edges.filter(image => image.node.room_name === filter).length,
    }))
  }, [photos])

  const setFilter = filter => {
    setPhotoFilter(filter)
    onSetPhotoFilter?.()
  }

  const filteredPhotos = selectedPhotoFilter
    ? photos.edges
        .map((photo, index) => ({ ...photo, originalIndex: index })) // originalIndex helps set the index of a selected photo (photoIndex)
        .filter(photo => photo.node.room_name?.match(selectedPhotoFilter))
    : photos.edges

  return {
    selectedPhotoFilter,
    photoFilters,
    filteredPhotos,
    setFilter,
  }
}
