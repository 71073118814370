import React from 'react'
import { Box, ImageList, ImageListItem, Skeleton } from '@mui/material'
import PhotoFilters from './PhotoFilters'
import { usePhotoList } from '../../../../CustomHooks/usePhotoList'
import PhotoModalHeader from '../../../../Homebuyer/Pages/Listing/Header/PhotoModalHeader'
import { usePhotoFilters } from '../../../../CustomHooks/usePhotoFilters'
import useKeyPress from '../../../../CustomHooks/useKeyPress'

const DesktopPhotoList = ({
  photos,
  onCloseModal,
  goBack,
  address,
  photoIndex,
  setPhotoIndex,
  onClickListPhoto,
}) => {
  const { itemRefs, scrollableContainerRef, loadedImages, handleImageLoad, scrollToTop } =
    usePhotoList(photos, photoIndex, setPhotoIndex)
  const { selectedPhotoFilter, photoFilters, setFilter, filteredPhotos } = usePhotoFilters(
    photos,
    scrollToTop
  )
  useKeyPress('Escape', onCloseModal)

  const getItemSize = photoIndex => {
    const isFinalPhoto = photoIndex + 1 === filteredPhotos.length
    const isSecondLastPhoto = photoIndex + 2 === filteredPhotos.length
    const finalRowPhotoCount = filteredPhotos.length % 3

    // Handle final row cases (1 or 2 photos remaining)
    if (finalRowPhotoCount === 1 && isFinalPhoto) {
      return { rows: 4, cols: 6 } // Single photo takes full width
    }
    if (finalRowPhotoCount === 2 && (isFinalPhoto || isSecondLastPhoto)) {
      return { rows: 3, cols: 3 } // Two photos split evenly
    }
    // Large photos that alternate from left to right in the grid
    const isLargePhoto = photoIndex % 6 === 0 || (photoIndex - 4) % 6 === 0
    if (isLargePhoto) {
      return { rows: 4, cols: 4 }
    }
    // Default small photo
    return { rows: 2, cols: 2 }
  }

  return (
    <Box position="relative" id="desktop-photo-list" sx={{ height: '97vh' }}>
      <PhotoModalHeader
        onCloseModal={onCloseModal}
        addressProp={address}
        onClickBack={goBack}
        backLabel={'Listing Details'}
      />
      {photoFilters.length > 0 && (
        <PhotoFilters
          photoCount={photos.edges.length}
          photoFilters={photoFilters}
          selectedPhotoFilter={selectedPhotoFilter}
          setFilter={setFilter}
        />
      )}
      <ImageList
        ref={scrollableContainerRef}
        variant="quilted"
        rowHeight={150}
        cols={6}
        gap={8}
        sx={{
          width: '100%',
          maxWidth: 1600,
          mx: 'auto',
          height: filteredPhotos.length > 3 ? '79vh' : 'auto',
          paddingX: theme => theme.spacing(9),
          paddingY: theme => theme.spacing(2),
        }}
      >
        {filteredPhotos.map((photo, index) => {
          const { rows, cols } = getItemSize(index)

          return (
            <ImageListItem
              key={photo.node.photo_url}
              cols={cols}
              rows={rows}
              ref={el => (itemRefs.current[index] = el)}
              sx={{ position: 'relative' }}
            >
              {!loadedImages[photo.node.photo_url] && (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              )}
              <img
                loading="lazy"
                src={photo.node.photo_url}
                alt={photo.node.room_name}
                style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                onClick={() => onClickListPhoto(photo.originalIndex || index)}
                onLoad={() => handleImageLoad(photo.node.photo_url)}
              />
            </ImageListItem>
          )
        })}
      </ImageList>
    </Box>
  )
}

export default DesktopPhotoList
